import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Pause: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 180 180" fill="none" {...props}>
      <g
        filter="url(#prefix__filter0_d_5327_9905)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      >
        <path d="M70.586 43h-4.862C60.354 43 56 47.23 56 52.45v75.6c0 5.219 4.354 9.45 9.724 9.45h4.862c5.37 0 9.724-4.231 9.724-9.45v-75.6c0-5.22-4.354-9.45-9.724-9.45zM114.344 43h-4.862c-5.37 0-9.724 4.23-9.724 9.45v75.6c0 5.219 4.354 9.45 9.724 9.45h4.862c5.37 0 9.724-4.231 9.724-9.45v-75.6c0-5.22-4.354-9.45-9.724-9.45z" />
      </g>
      <defs>
        <filter
          id="prefix__filter0_d_5327_9905"
          x={53.761}
          y={41.822}
          width={72.546}
          height={98.978}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1.061} />
          <feGaussianBlur stdDeviation={1.119} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5327_9905"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5327_9905"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

const MemoPause = React.memo(Pause);
export default MemoPause;
