import { useAppDispatch } from "./../store/store";
import { findCategory } from "app/model/category";
import { selectUserState, userActions } from "app/store/slices/user";
import { useAppSelector } from "app/store/store";
import { useCallback, useMemo, useState } from "react";
import {
  usePostAccountUsersMePresets,
  useDeleteAccountUsersMePresets,
} from "services-hooks/hooks";
import { Category } from "services-hooks/types";
import { useNavigatePath } from "./navigation.hook";

export const useFavorites = (customAuthGuard?: VoidFunction | (() => Promise<void>)) => {
  const dispatch = useAppDispatch();
  const navigatePath = useNavigatePath();
  const { session, favorites, favoritesLoading } =
    useAppSelector(selectUserState);
  const { mutateAsync: addFavorite, isLoading: addToFavoriteLoading } =
    usePostAccountUsersMePresets();
  const { mutateAsync: deleteFavorite, isLoading: deleteFavoriteLoading } =
    useDeleteAccountUsersMePresets();
  const favoritesPending = useMemo(() => {
    return favoritesLoading || addToFavoriteLoading || deleteFavoriteLoading;
  }, [favoritesLoading || addToFavoriteLoading || deleteFavoriteLoading]);

  const addToFavorites = async (
    category: Category,
    isPlaying: boolean = false
  ) => {
    try {
      await addFavorite({
        requestBody: [{ permalink: category.permalink! }],
        queryParams: { live: isPlaying },
      });
      dispatch(userActions.loadFavorites());
    } catch (e) {
      console.error(e);
    }
  };

  const removeFromFavorites = async (
    category: Category,
    isPlaying: boolean = false
  ) => {
    try {
      await deleteFavorite({
        queryParams: { live: isPlaying },
        requestBody: [{ permalink: category.permalink! }],
      });
      dispatch(userActions.loadFavorites());
    } catch (e) {
      console.error(e);
    }
  };

  const isFavoriteCategory = (category: Category) =>
    !!findCategory(favorites || [], category);

  const toggleFavorite = async (category: Category, isPlaying: boolean = false) => {
    if (session?.user) {
      if (!favoritesPending) {
        if (isFavoriteCategory(category)) {
          removeFromFavorites(category, isPlaying);
        } else {
          addToFavorites(category, isPlaying);
        }
      }
    } else {
      if (customAuthGuard) {
        await customAuthGuard()
      } else {
        navigatePath('/log-in');
      }
    }
  };

  return {
    toggleFavorite,
    isFavoriteCategory,
    loading: favoritesPending,
    addToFavorites,
    removeFromFavorites
  };
};
