import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Globe: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.667A8.336 8.336 0 001.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667zM3.333 10c0-.508.067-1.008.175-1.483L7.492 12.5v.833c0 .917.75 1.667 1.666 1.667v1.608C5.883 16.192 3.333 13.392 3.333 10zm9.992 3.333c.75 0 1.367.492 1.583 1.167a6.66 6.66 0 001.759-4.5 6.67 6.67 0 00-4.175-6.175v.341c0 .917-.75 1.667-1.667 1.667H9.158V7.5a.836.836 0 01-.833.833H6.658V10h5c.459 0 .834.375.834.833v2.5h.833z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoGlobe = React.memo(Globe);
export default MemoGlobe;
