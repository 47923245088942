import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const HeartFull: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 73 68" fill="none" {...props} style={{filter: 'drop-shadow(0px 2.4px 4.8px rgba(0, 0, 0, 0.25))'}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.151.25c-4.737 0-7.791.578-11.292 2.335a20.126 20.126 0 00-3.642 2.346 20.55 20.55 0 00-3.496-2.239C29.153.883 25.941.25 21.374.25 9.14.25.25 10.638.25 24.129c0 10.189 5.543 20.03 15.869 29.547 5.42 4.996 12.34 9.94 17.295 12.57l2.836 1.504 2.836-1.505c4.955-2.63 11.875-7.573 17.295-12.569C66.707 44.16 72.25 34.319 72.25 24.13 72.25 10.781 63.276.302 51.151.25z"
        fill="#FF3A3A"
      />
    </svg>
  );
}

const MemoHeartFull = React.memo(HeartFull);
export default MemoHeartFull;
